import { SafeAreaView, TouchableOpacity, View, Text } from "react-native";
import { useCallback, useEffect, useState } from "react";
import { useNavigation } from "@react-navigation/native";
import { HomeScreenNavigationProp, PaymentScreenNavigationProp, routeHome, routePayment, routeTitleDetails, TitleDetailsScreenNavigationProp } from "../../Routes";
import { mid_blue, white_smoke, sm, md, lg, xl, x2l, highlightColor } from "../../StyleHelpers";
import { Metadata } from "../../models/Metadata";
import styled, { css } from "styled-components/native";
import styledRN from "rn-css";
import TopAppBar from "../../components/TopAppBar/index";
import closeIcon from "../../assets/TopNavBarIcon/icon_nav_close.svg";
import backIcon from "../../assets/TopNavBarIcon/icon_nav_back.svg";
import ComponentTypeEnum from "../../models/ComponentTypeEnum";
import Toast from "../../components/Toast";
import { AsyncStorageKeys, CarActivity, PurchaseTransactionType } from "../../Types";
import AsyncStorage from "@react-native-async-storage/async-storage";
import useGenericContentStore from "../../store/genericContent.store";
import { AppLogger } from "../../utils/AppLogger";
import { capitalize, convertToLanguage, countryDataList, generateSecurityCodePlaceholder, getCurrencySymbol, globalAny } from "../../utils/Utils";
import { Content } from "@joaosousa/react-native-progress-steps";
import Marker from "../../components/Stepper/Marker/Marker";
import { Title } from "../../components/Stepper/Title";
import { Stepper } from "../../components/Stepper";
import BillingInformation from "./BillingInformation";
import TouchableButton from "../../components/TouchableButton";
import ComponentThemeType from "../../models/ComponentTheme";
import CardDetails from "./CardDetails";
import CustomizeDialogBox from "../../components/DialogMessageBox/CustomizeDialogBox";
import { checkEnrollment, paymentAuthorization, productPrice, purchaseHistory, setup, tax } from "../../services/paymentService";
import { addTransactionEvent, confirm, generateCaptureContext, preverify, verify } from "../../services/purchaseService";
import PurchaseOptions from "./PurchaseOptions";
import PrivacyPolicy from "../../components/PrivacyPolicy";
import useLegalContentStore from "../../store/useLegalContent.store";
import PaymentLoader from "../../components/Loaders/PaymentLoader";
import usePaymentTransactionStore from "../../store/usePaymentTransaction.store";
import { ProductTierDetails } from "../../models/ProductTierDetails";
import { TaxAddress } from "../../models/TaxAddress";
import { TaxModel } from "../../models/TaxModel";
import * as CacheStorageUtils from "../../utils/CacheStorageUtils";
import { accountCredits, accountCreditsExpiration } from "../../services/accountService";
import CarActivityDialog from "../../components/CarActivityDialog";
import useMovieContentStore from "../../store/useMovieContent.store";
import useDrivingStore from "../../store/useDriving.store";
import ENVIRONMENT from "../../config/ENVIRONMENT";
import { DEFAULT_ENV, hostUrl } from "../../config/ENV_CONFIG";
import { Modal as ModalPaper, Portal, Provider } from "react-native-paper";
import { getAvailableAudioLanguages } from "../../services/videoService";
import { responseOk } from "../../utils/ValidateResponse";
import { getAvailability } from "../../services/metadataService";
import { debounce } from "lodash";
import { PaymentGateway } from "../../enums/PaymentGateway";
import { PurchaseProductType } from "../../enums/PurchaseProductType";
import { PaymentMethod } from "../../enums/PaymentMethod";
import { SetupRequest } from "../../models/request/SetupRequest";
import { TransactionEventType } from "../../enums/TransactionEventType";
import { CheckEnrollmentStatus } from "../../enums/CheckEnrollmentStatus";
import { PaymentAuthorizationRequest } from "../../models/request/PaymentAuthorizationRequest";
import { CheckEnrollmentRequest } from "../../models/request/CheckEnrollmentRequest";
import { jwtDecode } from "jwt-decode";
import { CaptureContextJwtPayload } from "../../models/payment/CaptureContextJwtPayload";

interface StyledProps {
	isTermAndService: boolean;
}

const StyledContainer = styled.View<StyledProps>`
	background-color: rgb(20, 20, 20);

	${(props) =>
		!props.isTermAndService &&
		css`
			display: flex;
			flex-direction: column;
			align-items: center;
			height: 100%;
		`}
`;

const StyledFormContainer = styledRN.View<any>`
	display: ${(props) => (props.isShow ? "block" : "none")};
	padding-top: 200px;
	padding-bottom: 60px;
	width: 56vw;
	z-index: 999;
	
	@media (min-width: 100) {
		width: 98vw;
	}

	@media (min-width: ${sm}) {
		width: 98vw;
	}

	@media (min-width: ${md}) {
		width: 86vw;
	}

	@media (min-width: ${lg}) {
		width: 76vw;
	}

	@media (min-width: ${xl}) {
		width: 66vw;
	}

	@media (min-width: ${x2l}) {
		width: 56vw;
	}
`;

const StyledButtonContainer = styledRN.View`
	margin-bottom: 20px;
	margin-top: 30px;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-between;

	@media (min-width: 100) {
		width: 98vw;
	}

	@media (min-width: ${sm}) {
		width: 96vw;
		bottom:5%;
	}

	@media (min-width: ${md}) {
		width: 86vw;
	}

	@media (min-width: ${lg}) {
		width: 76vw;
	}

	@media (min-width: ${xl}) {
		width: 66vw;
	}

	@media (min-width: ${x2l}) {
		width: 56vw;
	}
	@media (min-width: 1728) {
		width: 56vw;
		margin-top: 100px;
	}
`;

const StyledButton = styled.View`
	width: 49%;
`;

const StyledModal = styled(ModalPaper)`
	background-color: rgba(0, 0, 0, 0.9);
	height: 100vh;
	width: 100vw;
	align-items: center;
	position: fixed;
	z-index: 1005;
`;

const StyledSubmitButton = styled.View`
	display: flex;
	justify-content: center;
	align-items: center;
	margin-top: 10px;
`;

//For Flex microform creation
let number: any;
let securityCode: any;
const rent = "Rent";
let codeLength: any;
let isEmptyCardNumber = true;
let isEmptySecurityNumber = true;

//For V8 payment
const paymentGateway: PaymentGateway = PaymentGateway.CyberSource;
const purchaseProductType: PurchaseProductType = PurchaseProductType.ParentProduct;
const paymentMethod: PaymentMethod = PaymentMethod.CreditCard;
const transactionMode: string = "S";

const PaymentScreen = (props: any) => {
	const navigationHome = useNavigation<HomeScreenNavigationProp>();
	const navigation = useNavigation<any>();
	const navigationPayment = useNavigation<PaymentScreenNavigationProp>();
	const navigationTitleDetails = useNavigation<TitleDetailsScreenNavigationProp>();
	const { route } = props;
	const { parentProductId, paymentType } = route.params;
	const [metadata, setMetadata] = useState<Metadata>();
	const [visible, setVisible] = useState(false);
	const [toastMessage, setToastMessage] = useState("");
	const [title, setTitle] = useState("");
	const [termOfService, setTermOfService] = useState(false);
	const [isTermOfService, setIsTermOfService] = useState(false);
	const [labelText, setLabelText] = useState("");
	const [type, setType] = useState(ComponentTypeEnum.Secondary);
	const onDismissSnackBar = () => setVisible(false);
	const movieStoreValue = useMovieContentStore((state) => state.data);
	const fetchTitleDetails = useMovieContentStore((state) => state.fetchTitleDetails);
	const setSelectedTabs = useGenericContentStore((state: any) => state.setSelectedTabs);
	const setIsSuccess = useGenericContentStore((state: any) => state.setIsSuccess);
	const purchasePrice = usePaymentTransactionStore((state: any) => state.purchasePrice);
	const rentPrice = usePaymentTransactionStore((state: any) => state.rentPrice);
	const setPurchasePrice = usePaymentTransactionStore((state: any) => state.setPurchasePrice);
	const setRentPrice = usePaymentTransactionStore((state: any) => state.setRentPrice);
	const currencySymbol = usePaymentTransactionStore((state: any) => state.currencySymbol);
	const [step, setStep] = useState(0);
	const [showConfirmModal, setShowConfirmModal] = useState(false);
	const [confirmCallBack, setConfirmCallBack] = useState("");
	const [captureContext, setCaptureContext] = useState<any>();
	const [flexMicroFormScriptLoaded, setFlexMicroFormScriptLoaded] = useState<boolean>(false);
	const [flexMicroform, setFlexMicroform] = useState();
	const [transactionId, setTransactionId] = useState("");
	const [transientToken, setTransientToken] = useState("");
	const [product, setProduct] = useState<ProductTierDetails>();
	const [taxDetails, setTaxDetails] = useState<TaxModel>();
	const [validatedAddress, setValidatedAddress] = useState<TaxAddress>();
	const mistakeCount = useGenericContentStore((state: any) => state.mistakeCount);
	const setMistakeCount = useGenericContentStore((state: any) => state.setMistakeCount);
	const carActivity = useDrivingStore((state: any) => state.carActivity);
	const setIsRenderRedeem = useGenericContentStore((state: any) => state.setIsRenderRedeem);
	const [isProcessing, setIsProcessing] = useState(false);
	const [isShow, setIsShow] = useState(false);
	const [isProcessPurchase, setIsProcessPurchase] = useState(false);
	const [deviceDataCollectionUrl, setDeviceDataCollectionUrl] = useState("");
	const [cardinalAccessToken, setCardinalAccessToken] = useState("");
	const [stepUrl, setStepUrl] = useState("");
	const [stepUrlAccessToken, setStepUrlAccessToken] = useState("");
	const [sessionId, setSessionId] = useState("");
	const [showAuthPayer, setShowAuthPayer] = useState(false);
	const [movieAvailableLang, setMovieAvailableLang] = useState("");
	const [disabledNext, setDisabledNext] = useState<any>(false);
	const [disabledPrevious, setDisabledPrevious] = useState(false);
	const [isFlexLimit, setIsFlexLimit] = useState(false);
	const [toastDuration, setToastDuration] = useState(5500);

	// ProductCheckout Related States
	// Personal Info
	const [firstName, setFirstName] = useState("");
	const [lastName, setLastName] = useState("");

	// Card Details
	const [cardName, setCardName] = useState("");
	const [cardNumberDetails, setCardNumber] = useState(true);
	const [cardExpiry, setCardExpiry] = useState("");
	const [cardCVV, setCardCVV] = useState(true);

	// Billing Info
	const [address, setAddress] = useState("");
	const [address2, setAddress2] = useState("");
	const [country, setCountry] = useState("");
	const [state, setState] = useState("");
	const [city, setCity] = useState("");
	const [postalCode, setPostalCode] = useState("");
	const [countryName, setCountryName] = useState("");
	const [stateName, setStateName] = useState("");

	// end of ProductCheckout Related States
	const legalStoreValue = useLegalContentStore((state: any) => state.data);

	//Calculate tax state
	const [validateTax, setValidateTax] = useState(false);

	const wording = {
		movieNotAvailable: globalAny.language.movie_not_available_user,
		movieAlreadyOwned: globalAny.language.movie_already_owned,
		errorValidation: globalAny.language.error_validation,
		validatedAddress: globalAny.language.validated_address,
		retryLimit: globalAny.language.retry_limit,
		invalidSecurityCode: globalAny.language.invalid_security_code,
		invalidCreditCard: globalAny.language.invalid_credit_card,
		errorOccurred: globalAny.language.error_occurred,
		validatingDoNotStray: globalAny.language.validating_do_not_stray,
		taxApply: globalAny.language.tax_apply,
		invalidExpiryDate: globalAny.language.invalid_expiry_date,
		authenticationFailed: globalAny.language.authentication_failed,
	};

	const stylesIframe = {
		display: "none",
	};

	const customizeStyle = {
		background: white_smoke,
		padding: 20,
		zIndex: 1005,
		borderRadius: 16,
	};

	//#region Function
	const startCountdownAndHandleError = async () => {
		try {
			const currentTime = new Date().getTime();
			const waitingPeriod = 15 * 1000;
			const newAllowUntil = currentTime + waitingPeriod;
			await AsyncStorage.setItem(AsyncStorageKeys.allowUntil, newAllowUntil.toString());
		} catch (error) {
			AppLogger.error(error);
		}
	};

	const init = () => {
		try {
			fetchTitleDetails(parentProductId, false);
			fetchPrice();
			fetchAudioLanguages();
			setIsRenderRedeem(false);
		} catch (err) {
			AppLogger.log(err);
		}
	};

	const initFlexMicroFormScript = (captureContext: string) => {
		const scriptId = "flexMicroFormScript";
		let scriptElement = document.getElementById(scriptId) as HTMLScriptElement;

		if (scriptElement) {
			document.body.removeChild(scriptElement);
		}

		const decodedJwt = jwtDecode<CaptureContextJwtPayload>(captureContext);
		const { clientLibrary, clientLibraryIntegrity } = decodedJwt.ctx[0].data;

		scriptElement = document.createElement("script");
		scriptElement.id = scriptId;
		scriptElement.type = "text/javascript";
		scriptElement.async = true;
		scriptElement.src = clientLibrary;
		scriptElement.integrity = clientLibraryIntegrity;
		scriptElement.crossOrigin = "anonymous";
		document.body.appendChild(scriptElement);

		setFlexMicroFormScriptLoaded(true);
	};

	const generate3dsCallbackScript = () => {
		return `
		function closeConsumerAuthModal() {
			document.querySelector('#cs-3ds-callback-button').click();
		}
		`;
	};

	const init3dsCallback = () => {
		const scriptId = "threeDSCallbackScript";
		let scriptElement = document.getElementById(scriptId) as HTMLScriptElement;
		if (!scriptElement) {
			scriptElement = document.createElement("script");
			scriptElement.id = scriptId;
			scriptElement.type = "text/javascript";
			scriptElement.async = true;
			scriptElement.text = generate3dsCallbackScript();
			document.body.appendChild(scriptElement);
		}
	};

	const initialize3dsPayerSetup = async () => {
		const payerSetupRequest: SetupRequest = {
			paymentData: {
				token: transientToken,
				paymentMethod: paymentMethod,
			},
		};
		setIsProcessPurchase(true);

		const payerSetupResponse = await setup(transactionId, payerSetupRequest);
		if (payerSetupResponse.responseCode !== 10000) {
			await addTransactionEvent(transactionId, TransactionEventType.SetupError, JSON.stringify(payerSetupResponse));
			purchaseCheckoutError();
			setIsProcessPurchase(false);
			return;
		}

		// setup success
		setCardinalAccessToken(payerSetupResponse.accessToken);
		setDeviceDataCollectionUrl(payerSetupResponse.deviceDataCollectionUrl);
		//Device Data Collection submit
		setTimeout(() => {
			const cardinalCollectionForm = document.querySelector("#cardinal_collection_form") as HTMLFormElement;
			if (cardinalCollectionForm) {
				// form exists
				cardinalCollectionForm.submit();
			}
		}, 1000);
	};

	const getReturnUrl = () => {
		if (DEFAULT_ENV === ENVIRONMENT.UAT) {
			return "https://mercedes.uat.ridevu.com/purchase/v1/redirect";
		} else if (DEFAULT_ENV === ENVIRONMENT.PRODUCTION) {
			return "https://mercedes.ridevu.com/purchase/v1/redirect";
		} else {
			return "https://mercedes.staging.ridevu.com/purchase/v1/redirect";
		}
	};

	const onCheckEnrollment = async (referenceId: string) => {
		const payerCheckRequest: CheckEnrollmentRequest = {
			paymentData: {
				token: transientToken,
				paymentMethod: paymentMethod,
			},
			authData: {
				referenceId: referenceId,
				transactionMode: transactionMode,
				returnUrl: getReturnUrl(),
			},
			billingAddress: {
				firstName: firstName,
				lastName: lastName,
				email: localStorage.getItem("email") ?? "test@gmail.com",
				line1: address,
				line2: address2,
				country: country,
				region: state,
				postalCode: postalCode,
				city: city,
				phoneNumber: null,
			},
			deviceInformation: {
				httpBrowserJavaEnabled: null,
				httpBrowserLanguage: window.navigator.language,
				httpBrowserColorDepth: window.screen.colorDepth,
				httpBrowserTimeDifference: null,
				httpBrowserJavaScriptEnabled: true,
				httpBrowserScreenHeight: window.screen.height,
				httpBrowserScreenWidth: window.screen.width,
			},
		};

		const payerCheckResponse = await checkEnrollment(transactionId, payerCheckRequest);

		if (payerCheckResponse.responseCode !== 10000) {
			await addTransactionEvent(transactionId, TransactionEventType.CheckEnrollmentError, JSON.stringify(payerCheckResponse));
			purchaseCheckoutError();
			setIsProcessPurchase(false);
			return;
		}

		if (payerCheckResponse.status === CheckEnrollmentStatus.Pending) {
			setStepUrl(payerCheckResponse.stepUpUrl);
			setStepUrlAccessToken(payerCheckResponse.accessToken);
			setShowAuthPayer(true);

			//Step up form submit
			setTimeout(() => {
				const stepUpForm = document.querySelector("#step-up-form") as HTMLFormElement;

				if (stepUpForm) {
					// form exists
					stepUpForm.submit();
				}
				setIsProcessPurchase(false);
			}, 1000);
		} else if (payerCheckResponse.status === CheckEnrollmentStatus.Successful) {
			pay();
		} else if (payerCheckResponse.status === CheckEnrollmentStatus.Failed) {
			setVisible(false);
			popUpError(ComponentTypeEnum.Error, wording.authenticationFailed, 5500);
			setIsProcessPurchase(false);
		} else {
			purchaseCheckoutError();
			setIsProcessPurchase(false);
		}
	};

	const onPressToast = (command: string) => {
		if (command === globalAny.language.label_retry) {
			navigationPayment.replace(routePayment, { parentProductId, paymentType: paymentType });
			return;
		}

		initialize3dsPayerSetup();
	};

	/**
	 * Fetch price of current ProductId
	 * Note: currency symbol is not yet dynamic.
	 */
	const fetchPrice = async () => {
		const prices: any = await productPrice(parentProductId);
		const [purchasePrice] =
			prices?.productTierDetails?.filter((product: any) => product.transactionTypeId === PurchaseTransactionType.Purchase) ?? [];
		const [rentPrice] = prices?.productTierDetails?.filter((product: any) => product.transactionTypeId === PurchaseTransactionType.Rent) ?? [];

		if (purchasePrice) {
			setPurchasePrice(purchasePrice?.price);
		}

		if (rentPrice) {
			setRentPrice(rentPrice?.price);
		}

		return prices;
	};

	const getPaymentType = () => {
		return capitalize(paymentType) === rent ? PurchaseTransactionType.Rent : PurchaseTransactionType.Purchase;
	};

	const transactionDetailsError = () => {
		setVisible(false);
		startCountdownAndHandleError();
		setLabelText("");
		AppLogger.error("transactionDetailsError", "error");
		popUpError(ComponentTypeEnum.Error, wording.errorValidation, 5500);
		setTimeout(() => {
			navigationTitleDetails.navigate(routeTitleDetails, { parentProductId });
		}, 5000);
	};

	const initTransactionDetails = async () => {
		try {
			const prices = await fetchPrice();
			if (prices.responseCode !== 10000) {
				transactionDetailsError();
				return;
			}
			const [purchaseTypePrice] = prices.productTierDetails.filter((product: any) => product.transactionTypeId === getPaymentType());
			setProduct(purchaseTypePrice);

			const preverifyResponse = await preverify(parentProductId, paymentGateway, purchaseProductType, purchaseTypePrice as ProductTierDetails);
			if (preverifyResponse.responseCode !== 10000) {
				transactionDetailsError();
				return;
			}
			setTransactionId(preverifyResponse?.transactionId);
			const captureContextResponse = await generateCaptureContext(preverifyResponse?.transactionId);
			if (captureContextResponse.responseCode !== 10000) {
				await addTransactionEvent(
					preverifyResponse?.transactionId,
					TransactionEventType.CaptureContextError,
					JSON.stringify(captureContextResponse)
				);
				transactionDetailsError();
				return;
			}

			initFlexMicroFormScript(captureContextResponse.captureContext);
			setCaptureContext(captureContextResponse.captureContext);
			setMistakeCount(0);
		} catch (error) {
			popUpError(ComponentTypeEnum.Error, wording.errorValidation, 5000);
		}
	};

	const popUpError = (type: ComponentTypeEnum, message: string, timeout: number, isLimit?: boolean) => {
		setType(type);
		setToastMessage(message);
		setToastDuration(timeout);
		setVisible(true);
		if (isLimit) {
			setTimeout(() => {
				back();
			}, timeout);
		}
	};

	const initFlexMicroform = (captureContextParam: string) => {
		if (!captureContextParam) {
			setVisible(false);
			popUpError(
				ComponentTypeEnum.Secondary,
				wording.errorOccurred.replace("%d", paymentType === rent.toLowerCase() ? globalAny.language.rent : globalAny.language.purchase_word),
				5500
			);
			return;
		}
		try {
			let cardNumber: any = document.querySelector("#number-container");
			let securityNumber: any = document.querySelector("#securityCode-container");
			let cardNumberLabel: any = document.querySelector("#floating-label");
			let securityNumberLabel: any = document.querySelector("#floating-label-securityCode");
			let cardNumberPadding: any = document.querySelector("#cardNumberPadding");
			let securityCodePadding: any = document.querySelector("#securityCodePadding");
			isEmptyCardNumber = true;
			isEmptySecurityNumber = true;

			let myStyles = {
				input: {
					"font-size": "25px",
					"font-weight": 400,
					"font-stretch": "normal",
					"font-style": "normal",
					color: white_smoke,
				},
				":focus": { color: white_smoke },
				":disabled": { cursor: "not-allowed" },
				valid: { color: white_smoke },
				invalid: { color: white_smoke },
				"::placeholder": {
					color: white_smoke,
					opacity: 0.8,
				},
			};

			// @ts-ignores
			let flex = new Flex(captureContextParam);
			let microform = flex.microform({ styles: myStyles });
			setFlexMicroform(microform);

			//micro form create field
			number = microform.createField("number", { placeholder: "", autoformat: false });
			securityCode = microform.createField("securityCode", {
				placeholder: "",
				autoformat: false,
			});

			//load the field
			number.load("#number-container");
			securityCode.load("#securityCode-container");

			number.on("change", function (val: any) {
				codeLength = val?.card[0]?.securityCode?.length;
				isEmptyCardNumber = !!val?.empty;
				setCardNumber(isEmptyCardNumber);
				debouncedOnChange();
			});

			securityCode.on("change", function (val: any) {
				isEmptySecurityNumber = !!val?.empty;
				setCardCVV(isEmptySecurityNumber);
				debouncedOnChange();
			});

			number.on("focus", function () {
				number.focus();
				number.update({ placeholder: "0000 0000 0000 0000" });
				cardNumberLabel.style.zIndex = 1;
				cardNumber.style.borderColor = mid_blue;
				cardNumberLabel.style.top = "-10px";
				cardNumberLabel.style.fontSize = "14px";
				cardNumberLabel.style.width = "auto";
				cardNumberPadding.style.paddingLeft = 0;
				cardNumberLabel.style.transition = "0.2s ease all";
			});

			securityCode.on("focus", function () {
				securityCode.focus();
				securityCode.update({ placeholder: generateSecurityCodePlaceholder(codeLength) || "000", autoformat: false });
				securityNumberLabel.style.zIndex = 1;
				securityNumber.style.borderColor = mid_blue;
				securityNumberLabel.style.top = "-10px";
				securityNumberLabel.style.fontSize = "14px";
				securityNumberLabel.style.width = "auto";
				securityCodePadding.style.paddingLeft = 0;
				securityNumberLabel.style.transition = "0.2s ease all";
			});

			number.on("blur", function () {
				cardNumber.style.borderColor = "rgb(147,143,153)";
				if (isEmptyCardNumber) {
					number.update({ placeholder: "" });
					cardNumberLabel.style.zIndex = -1;
					cardNumberLabel.style.top = "25px";
					cardNumberLabel.style.fontSize = "25px";
					cardNumberLabel.style.width = "235px";
					cardNumberPadding.style.paddingLeft = "10px";
					cardNumberLabel.style.transition = "0.2s ease all";
				}

				if (!isEmptySecurityNumber) {
					securityCode.update({ placeholder: generateSecurityCodePlaceholder(codeLength) || "000", autoformat: false });
				}
			});

			securityCode.on("blur", function () {
				securityNumber.style.borderColor = "rgb(147,143,153)";
				if (isEmptySecurityNumber) {
					securityCode.update({ placeholder: "" });
					cardNumberLabel.style.zIndex = -1;
					securityNumberLabel.style.top = "25px";
					securityNumberLabel.style.fontSize = "25px";
					securityNumberLabel.style.width = "80px";
					securityCodePadding.style.paddingLeft = "10px";
					securityNumberLabel.style.transition = "0.2s ease all";
				}
			});
		} catch (error: any) {
			setVisible(false);
			AppLogger.error("initFlexMicroform", error);
			popUpError(ComponentTypeEnum.Error, wording.errorValidation, 5500);
		}
	};

	const createTransientTokenError = (errorMessage: any) => {
		if (mistakeCount < 3) {
			setVisible(false);
			AppLogger.error("createTransientTokenError", "error");
			popUpError(ComponentTypeEnum.Error, errorMessage ?? wording.errorValidation, 5500);
			setLabelText(globalAny.language.label_retry);
			setMistakeCount(mistakeCount + 1);
			return;
		}
		startCountdownAndHandleError();

		const allowUntil = new Date().setMinutes(new Date().getMinutes() + 1);
		AsyncStorage.setItem(AsyncStorageKeys.allowUntil, allowUntil.toString());
		setLabelText("");
		setVisible(false);
		popUpError(ComponentTypeEnum.Error, wording.retryLimit, 5500, true);
	};

	const createTransientToken = () => {
		let expiryDate: any = document.querySelector("#expiryDate");
		let cardholderFirstName: any = document.querySelector("#cardholderFirstName");
		let cardholderLastName: any = document.querySelector("#cardholderLastName");

		let splitDate = expiryDate.value.split("/");
		let expMonth = splitDate[0];
		let expYear = splitDate[1];
		const today = new Date();
		const month = Number(today.getMonth() + 1);
		const year = Number(today.getFullYear().toString().substring(2));

		let options = {
			expirationMonth: expMonth,
			expirationYear: `20${expYear}`,
		};
		setDisabledNext(true);
		if ((Number(expMonth) < month && Number(expYear) <= year) || Number(expMonth) >= 13 || Number(expMonth) <= 0 || expMonth === "00") {
			expiryDate?.focus();
			createTransientTokenError(wording.invalidExpiryDate);
			return;
		}

		if (!cardholderFirstName.value || !cardholderLastName.value || !expiryDate.value) {
			setVisible(false);
			AppLogger.error("createTransientToken", "error");
			popUpError(ComponentTypeEnum.Error, wording.errorValidation, 5500);
			return;
		}

		// @ts-ignores
		flexMicroform.createToken(options, function (err, token) {
			try {
				const errLocation = err?.details[0]?.location;
				let errMessage: string = "";

				if (err) {
					if (errLocation === "number") {
						number?.focus();
						errMessage = wording.invalidCreditCard;
					} else if (errLocation === "expirationYear") {
						expiryDate?.focus();
						errMessage = wording.invalidExpiryDate;
					} else if (errLocation === "securityCode") {
						securityCode?.focus();
						errMessage = wording.invalidSecurityCode;
					} else {
						setDisabledNext(false);
						setIsFlexLimit(true);
						return;
					}
					setDisabledNext(false);
					createTransientTokenError(errMessage);
					AppLogger.error(err);
					return;
				}
				setDisabledNext(false);
				setTransientToken(token);
				setStep((prevStep) => prevStep + 1);
				if (DEFAULT_ENV !== ENVIRONMENT.PRODUCTION) AppLogger.log(`TransientToken: ${token}`);
				setTimeout(() => {
					setIsShow(true);
				}, 350);
			} catch (e) {
				setIsFlexLimit(true);
			}
		});
	};

	const onClose = () => {
		setSelectedTabs(0);
		setStep(0);
		navigationHome.replace(routeHome, {});
	};

	const back = () => {
		setStep(0);
		if (navigation.canGoBack()) {
			navigation.goBack();
			return;
		}
		navigationTitleDetails.navigate(routeTitleDetails, { parentProductId });
	};

	const getTitleBody = () => {
		const currentPaymentType = capitalize(paymentType);
		const isRent = currentPaymentType === rent;
		const word = wording.taxApply
			.replace("%d", paymentType === rent.toLowerCase() ? globalAny.language.rent : globalAny.language.purchase_word)
			.replace("%p", `${currencySymbol}${isRent ? rentPrice : purchasePrice}`);
		return word;
	};

	const onPress = (currentStep: number) => {
		if (onValidateStep(currentStep - 1) || currentStep <= step) {
			if (
				(step === 0 && currentStep < 2) ||
				(step === 1 && currentStep === 1) ||
				(step === 0 && currentStep === 1) ||
				(step === 2 && currentStep === 1)
			) {
				setTimeout(() => {
					setIsShow(true);
				}, 350);
			} else {
				setIsShow(false);
			}

			if (step === 0 && currentStep === 1) {
				createTransientToken();
				return;
			}

			if (step === 1 && currentStep === 2) {
				calculateTax(false);
				return;
			}

			setStep(currentStep);
		}
	};

	const calculateTaxError = () => {
		if (mistakeCount < 3) {
			setVisible(false);
			AppLogger.error("calculateTaxError", "error");
			popUpError(ComponentTypeEnum.Error, wording.errorValidation, 5500);
			setLabelText(globalAny.language.label_retry);
			setMistakeCount(mistakeCount + 1);
			return;
		}
		const allowUntil = new Date().setMinutes(new Date().getMinutes() + 1);
		AsyncStorage.setItem(AsyncStorageKeys.allowUntil, allowUntil.toString());
		setLabelText("");
		setVisible(false);
		popUpError(ComponentTypeEnum.Error, wording.retryLimit, 5500, true);
	};

	const calculateTax = async (useValidated: boolean) => {
		setIsProcessing(true);
		onValidateStep();
		setValidateTax(useValidated);
		const taxRequest: TaxAddress = {
			country: country,
			province: state,
			city: city,
			address: address,
			address2: address2,
			postalCode: postalCode,
		};

		const validatedRequest: any = validatedAddress;

		setVisible(true);
		setToastMessage(wording.validatingDoNotStray);
		setType(ComponentTypeEnum.Processing);
		setLabelText("");

		const result: any = await tax(transactionId, useValidated ? validatedRequest : taxRequest);
		if (result.responseCode === 10000) {
			setTaxDetails({
				price: addCurrency(result?.price, country, result?.currencyCode),
				totalAmount: addCurrency(result?.totalAmount, country, result?.currencyCode),
				taxAmount: addCurrency(result?.taxAmount, country, result?.currencyCode),
				taxRate: result?.taxRate.toFixed(2),
				taxType: result?.taxType,
			});
			setVisible(false);
			setIsShow(false);
			setStep((prevStep) => prevStep + 1);
			setMistakeCount(0);
			setIsProcessing(false);
			return;
		}
		setIsProcessing(false);
		calculateTaxError();
	};

	const addCurrency = (price: number, countryCode: string, currencyCode: any) => {
		try {
			const region = localStorage.getItem(AsyncStorageKeys.RegionOverride);
			let updatedPrice;
			const noDecimal = countryDataList?.control.some((item) => region === item.countryCode && item.noDecimal);

			if (noDecimal) {
				updatedPrice = price;
			} else {
				updatedPrice = price.toFixed(2);
			}

			return `${getCurrencySymbol(currencyCode)}${updatedPrice}`;
		} catch (error) {
			setVisible(false);
			AppLogger.error("addCurrency", "error");
			popUpError(ComponentTypeEnum.Error, wording.errorValidation, 5500);
		}
	};

	const onPressNext = () => {
		switch (step) {
			case 0:
				createTransientToken();
				return;
			case 1:
				calculateTax(false);
				return;
			case 2:
				setIsShow(false);
				initialize3dsPayerSetup();
				return;
			default:
				setStep((prevStep) => prevStep + 1);
				return;
		}
	};

	const onPressPrevious = () => {
		setDisabledPrevious(true);
		if (isTermOfService) {
			setIsTermOfService(false);
			return;
		}

		setTimeout(() => {
			setDisabledPrevious(false);
			setStep((prevStep) => prevStep - 1);
		}, 350);
	};

	const getConfirmCallBack = () => {
		setShowConfirmModal(false);
		setIsFlexLimit(false);
		switch (confirmCallBack) {
			case "back":
				return back();
			case "close":
				return onClose();
		}
	};

	/**
	 * checks if the step is valid to proceed
	 * @returns {boolean} - returns true if all required fields are filled up
	 */
	const onValidateStep = (currentStep?: any) => {
		const stepToProcess = currentStep ?? step;
		const isCardDetailsValid = firstName && lastName && !cardNumberDetails && cardExpiry && !cardCVV;
		const isBillingValid = address && country && state && city && postalCode;
		if (isProcessing) {
			return false;
		}

		if (stepToProcess === 0) {
			return isCardDetailsValid;
		} else if (stepToProcess === 1) {
			return isBillingValid;
		} else if (stepToProcess === 2) {
			return isCardDetailsValid && isBillingValid;
		}
	};

	const confirmExit = (callback: any) => {
		if (isTermOfService) {
			setIsTermOfService(false);
			return;
		}

		setIsFlexLimit(false);
		setShowConfirmModal(true);
		setConfirmCallBack(callback);
	};

	const onPressTermsAndService = async () => {
		setIsTermOfService(true);
		setTitle(globalAny.language.terms_of_service);
		const resultLegal = await legalStoreValue.legalData[AsyncStorageKeys.legals];
		const data = resultLegal?.legalContents?.filter((val: any) => val?.type === 3)[0].htmlContent;
		setTermOfService(data);
	};

	const purchaseCheckoutError = () => {
		if (mistakeCount < 3) {
			setVisible(false);
			AppLogger.error("purchaseCheckoutError", "error");
			popUpError(ComponentTypeEnum.Error, wording.errorValidation, 5500);
			setLabelText(globalAny.language.label_retry);
			setMistakeCount(mistakeCount + 1);
			return;
		}
		startCountdownAndHandleError();

		const allowUntil = new Date().setMinutes(new Date().getMinutes() + 1);
		AsyncStorage.setItem(AsyncStorageKeys.allowUntil, allowUntil.toString());
		setLabelText("");
		setVisible(false);
		popUpError(ComponentTypeEnum.Error, wording.retryLimit, 5500, true);
	};

	const closePayerAuth = () => {
		setShowAuthPayer(false);
		pay();
	};

	const debouncedOnChange = useCallback(
		debounce(() => {
			setDisabledNext(!!onValidateStep());
		}, 1000),
		[]
	);

	const pay = async () => {
		setIsProcessPurchase(true);
		setIsProcessing(true);
		onValidateStep();
		const checkoutDetails: PaymentAuthorizationRequest = {
			paymentData: {
				token: transientToken,
				paymentMethod: paymentMethod,
				saveCard: null,
			},
			billingAddress: {
				firstName: firstName,
				lastName: lastName,
				email: localStorage.getItem("email") ?? "test@gmail.com",
				line1: address,
				line2: address2,
				country: country,
				region: state,
				postalCode: postalCode,
				city: city,
				phoneNumber: null,
			},
			deviceFingerPrint: null,
		};

		const paymentAuthorizationResponse = await paymentAuthorization(transactionId, checkoutDetails);
		if (paymentAuthorizationResponse?.responseCode !== 10000) {
			await addTransactionEvent(transactionId, TransactionEventType.PaymentError, JSON.stringify(paymentAuthorizationResponse));
			setIsProcessPurchase(false);
			setIsProcessing(false);
			purchaseCheckoutError();
			return;
		}

		const verifyResponse = await verify(transactionId, parentProductId, paymentGateway, purchaseProductType, product as ProductTierDetails);
		if (verifyResponse?.responseCode !== 10000) {
			await addTransactionEvent(transactionId, TransactionEventType.VerifyError, JSON.stringify(verifyResponse));
			setIsProcessPurchase(false);
			setIsProcessing(false);
			purchaseCheckoutError();
			return;
		}

		const confirmResponse = await confirm(transactionId, paymentGateway, purchaseProductType, product as ProductTierDetails);
		if (confirmResponse?.responseCode !== 10000) {
			await addTransactionEvent(transactionId, TransactionEventType.ConfirmError, JSON.stringify(confirmResponse));
			setIsProcessPurchase(false);
			setIsProcessing(false);
			purchaseCheckoutError();
			return;
		}

		// successful payment if no error occured
		setIsSuccess(true, getPaymentType() === 3);
		const details: any = await CacheStorageUtils.getCacheItem("titledetails_" + parentProductId, true);
		if (details) {
			const newOwnedStatus = details["ownedstatus"].map((item: any) => {
				if (getPaymentType() === item.transactionType) {
					return { ...item, owned: true };
				}
				return item;
			});
			const newDetails = {
				images: details["images"],
				metadata: details["metadata"],
				ownedstatus: newOwnedStatus,
				vam: details["vam"],
			};
			await CacheStorageUtils.setCacheItem("titledetails_" + parentProductId, newDetails, true);
		}
		const promiseResult = await Promise.allSettled([accountCredits(), accountCreditsExpiration(), purchaseHistory()]);
		// @ts-ignore
		const [userCreditsData, userCreditsExpirationData, purchaseHistoryData] = promiseResult.map((i) => i.value);
		CacheStorageUtils.setCacheItem(AsyncStorageKeys.accountDetails, [userCreditsData, userCreditsExpirationData, purchaseHistoryData], true);
		fetchTitleDetails(parentProductId, true);
		setIsProcessPurchase(false);
		setIsProcessing(false);
		if (navigation.canGoBack()) {
			navigation.goBack();
			return;
		}
		navigationTitleDetails.navigate(routeTitleDetails, { parentProductId: parentProductId });
	};

	const fetchAudioLanguages = async () => {
		const availability: any = await getAvailability(parentProductId);
		const availableLanguages: any = await getAvailableAudioLanguages(parentProductId, 1080, availability?.transactionTypes[0]);
		if (availableLanguages.responseCode === responseOk) {
			const audio = convertToLanguage(availableLanguages.audioLanguages);
			const subtitle = convertToLanguage(availableLanguages.subtitleLanguages);
			setMovieAvailableLang(
				`${globalAny.language.available_audio_language} ${audio}<br> ${globalAny.language.available_closed_caption_language} ${subtitle}`
			);
		}
	};

	const retryTransactions = () => {
		navigation.replace(routePayment, { parentProductId, paymentType });
	};

	const steps = [
		{
			id: 0,
			title: <Title onPress={onPress}>{globalAny.language.card_details}</Title>,
			content: (
				<Content key={0}>
					<CardDetails
						cardName={cardName}
						setCardName={setCardName}
						cardExpiry={cardExpiry}
						setCardExpiry={setCardExpiry}
						firstName={firstName}
						setFirstName={setFirstName}
						lastName={lastName}
						setLastName={setLastName}
						debouncedOnChange={debouncedOnChange}
					/>
				</Content>
			),
		},
		{
			id: 1,
			title: <Title onPress={onPress}>{globalAny.language.billing_information}</Title>,
			content: (
				<View key={1}>
					<BillingInformation
						show={isShow}
						setAddress={setAddress}
						address={address}
						setAddress2={setAddress2}
						address2={address2}
						setCountry={setCountry}
						country={country}
						setState={setState}
						state={state}
						setCity={setCity}
						city={city}
						setPostalCode={setPostalCode}
						postalCode={postalCode}
						setCountryName={setCountryName}
						countryName={countryName}
						setStateName={setStateName}
						stateName={stateName}
						step={step}
					/>
				</View>
			),
		},
		{
			id: 2,
			title: <Title onPress={onPress}>{globalAny.language.review_your_order}</Title>,
			content: (
				<Content key={2}>
					<PurchaseOptions
						metadata={metadata}
						onPressTermsAndService={onPressTermsAndService}
						price={taxDetails}
						isRent={capitalize(paymentType) === rent}
					/>
				</Content>
			),
		},
	];
	//#endregion

	//#region Render Function
	const renderCardinalIframe = () => {
		return (
			<>
				<iframe title="cardinal" id="cardinal_collection_iframe" name="collectionIframe" height="10" width="10" style={stylesIframe}></iframe>
				<form id="cardinal_collection_form" method="POST" target="collectionIframe" action={deviceDataCollectionUrl}>
					<input id="cardinal_collection_form_input" type="hidden" name="JWT" value={cardinalAccessToken} />
				</form>
			</>
		);
	};

	const renderStepUpIframe = () => {
		return (
			<Provider>
				<Portal>
					<StyledModal visible={true} contentContainerStyle={customizeStyle}>
						<View>
							<iframe title="setup-up" name="step-up-iframe" height="400" width="400"></iframe>
							<form id="step-up-form" target="step-up-iframe" method="post" action={stepUrl}>
								<input type="hidden" name="JWT" value={stepUrlAccessToken} />
							</form>
						</View>
						{hostUrl.includes("localhost") ? (
							//  @ts-ignores
							<StyledSubmitButton>
								<TouchableOpacity onPress={closePayerAuth} style={{ backgroundColor: highlightColor, borderRadius: 8, padding: 5 }}>
									<Text style={{ color: white_smoke }}>{globalAny.language.submit}</Text>
								</TouchableOpacity>
							</StyledSubmitButton>
						) : (
							<></>
						)}
					</StyledModal>
				</Portal>
			</Provider>
		);
	};
	//#endregion

	//#region useEffect
	useEffect(() => {
		init();
		init3dsCallback();
		initTransactionDetails();
	}, []);

	useEffect(() => {
		if (captureContext && flexMicroFormScriptLoaded === true) {
			setTimeout(() => {
				initFlexMicroform(captureContext);
			}, 2000);
		}
	}, [captureContext, flexMicroFormScriptLoaded]);

	useEffect(() => {
		window.addEventListener(
			"message",
			function (event) {
				const cardinalStagingUrl = "https://centinelapistag.cardinalcommerce.com";
				const cardinalProdUrl = "https://centinelapi.cardinalcommerce.com";
				if (event.origin === cardinalStagingUrl || event.origin === cardinalProdUrl) {
					const eventDetails = JSON.parse(event.data);

					setSessionId(eventDetails["SessionId"]);
					AppLogger.log("Device data collection completed.");
				}
			},
			false
		);
	}, []);

	useEffect(() => {
		if (sessionId) {
			onCheckEnrollment(sessionId);
		}
	}, [sessionId]);

	useEffect(() => {
		(async () => {
			const result = movieStoreValue.titleDetails.titles[parentProductId];

			if (result?.metadata?.responseCode === 40058 || isNaN(parentProductId)) {
				setVisible(true);
				setToastMessage(wording.movieNotAvailable);
				setTimeout(() => {
					setSelectedTabs(0);
					location.replace("/");
				}, 3000);
				return;
			}

			if (result?.ownedstatus) {
				const [owned] = result?.ownedstatus?.filter((val: any) => val.owned === true).sort((a: any, b: any) => a - b);

				if (owned?.owned && owned?.transactionType !== 3) {
					setVisible(true);
					setToastMessage(wording.movieAlreadyOwned);
					setTimeout(() => {
						setSelectedTabs(0);
						location.replace("/");
					}, 3000);
				}
			}

			if (result?.metadata) {
				setMetadata(result?.metadata);
			}
		})();
	}, [route.params, movieStoreValue]);
	//#endregion

	return (
		<SafeAreaView style={{ flex: 1 }}>
			{captureContext && flexMicroFormScriptLoaded === true && (
				// @ts-ignores
				<StyledContainer isTermAndService={isTermOfService}>
					<TopAppBar
						leftIcon={closeIcon}
						leftTitleIcon={backIcon}
						leftIconWidth={92}
						screenTitle={isTermOfService ? title : metadata?.title}
						screenTitleBody={isTermOfService ? "" : getTitleBody()}
						onPressLeftIcon={() => confirmExit("close")}
						onPressLeftTitleIcon={() => confirmExit("back")}
					/>

					{/* @ts-ignores */}
					<StyledFormContainer isShow={!isTermOfService}>
						<Stepper marker={<Marker onPress={onPress} />} currentStep={step} steps={steps} />
					</StyledFormContainer>

					{!isTermOfService && (
						<>
							{/* @ts-ignores */}
							<StyledButtonContainer>
								{/* @ts-ignores */}
								<StyledButton>
									<TouchableButton
										componentTheme={ComponentThemeType.VinFast}
										type={ComponentTypeEnum.Secondary}
										onPress={() => (step > 0 ? onPressPrevious() : confirmExit("back"))}
										disabled={disabledPrevious}
									>
										{step > 0 ? globalAny.language.previous : globalAny.language.cancel}
									</TouchableButton>
								</StyledButton>
								{/* @ts-ignores */}
								<StyledButton>
									<TouchableButton
										nativeID={"payNext"}
										componentTheme={ComponentThemeType.VinFast}
										type={ComponentTypeEnum.Primary}
										onPress={onPressNext}
										disabled={!onValidateStep() || disabledNext}
									>
										{step < steps.length - 1
											? globalAny.language.next
											: capitalize(paymentType) === rent
											? globalAny.language.rent
											: globalAny.language.purchase_word}
									</TouchableButton>
								</StyledButton>
							</StyledButtonContainer>
						</>
					)}
				</StyledContainer>
			)}
			{!captureContext && <PaymentLoader />}
			{isTermOfService && <PrivacyPolicy text={termOfService} />}

			<Toast
				visible={visible}
				text={toastMessage}
				label={labelText}
				type={type}
				onDismissSnackBar={onDismissSnackBar}
				duration={toastDuration}
				onPress={() => onPressToast(labelText)}
			/>

			{showConfirmModal && (
				<CustomizeDialogBox
					Header={
						capitalize(paymentType) === rent ? globalAny.language.cancel_rent_transaction : globalAny.language.cancel_purchase_transaction
					}
					Body={globalAny.language.validating_cancel_transaction}
					ButtonTextRight={globalAny.language.cancel}
					ButtonTextLeft={globalAny.language.ok.toUpperCase()}
					SingleButton={false}
					Width={680}
					Height={"auto"}
					TypeButtonColorLeft={ComponentTypeEnum.Primary}
					TypeButtonColorRight={ComponentTypeEnum.Secondary}
					onPressRight={() => setShowConfirmModal(false)}
					onPressLeft={() => {
						startCountdownAndHandleError();
						getConfirmCallBack();
					}}
				/>
			)}

			{isFlexLimit && (
				<CustomizeDialogBox
					Header={
						capitalize(paymentType) === rent ? globalAny.language.cancel_rent_transaction : globalAny.language.cancel_purchase_transaction
					}
					Body={globalAny.language.flex_limit_message}
					ButtonTextRight={globalAny.language.ok.toUpperCase()}
					SingleButton={true}
					Width={680}
					Height={"auto"}
					TypeButtonColorRight={ComponentTypeEnum.Primary}
					onPressRight={() => retryTransactions()}
				/>
			)}

			{isProcessPurchase && (
				<CustomizeDialogBox
					Header={metadata?.title}
					Body={globalAny.language.processing_order}
					Footer={movieAvailableLang}
					ButtonTextRight={globalAny.language.processing}
					SingleButton={!!isProcessPurchase}
					CloseButton={false}
					Width={680}
					Height={"auto"}
					TypeButtonColorRight={ComponentTypeEnum.Secondary}
					isProcessing={!!isProcessPurchase}
				/>
			)}

			<CarActivityDialog
				title={globalAny.language.car_in_motion_title}
				body={globalAny.language.car_in_motion_body}
				buttonText={globalAny.language.car_in_motion_back_to_movie}
				subText={globalAny.language.car_in_motion_subtext}
				onPress={() => {
					setSelectedTabs(0);
					navigationTitleDetails.replace(routeTitleDetails, { parentProductId });
				}}
				show={carActivity === CarActivity.INMOTION}
			/>
			<button id="cs-3ds-callback-button" name="cs-3ds-callback-button" type="submit" onClick={closePayerAuth} hidden></button>
			{showAuthPayer ? renderStepUpIframe() : <></>}
			{renderCardinalIframe()}
		</SafeAreaView>
	);
};

export default PaymentScreen;
